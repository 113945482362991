import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import axios from 'axios';
//import PrivateRoute from './Routes/PrivateRoute';
//import usePageTitle from './Components/_GlobalFunctions/PageTitle';
import Handshake from './Components/Handshake/Handshake';
import { MainTheme } from './Themes/MainTheme';
import Home from './Components/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import Login from './Components/Login/Login';
import LoginCheckRoute from './Routes/LoginCheckRoute';
import AccountSettings from './Components/AccountSettings/AccountSettings';
import UserPreferences from './Components/UserPreferences/UserPreferences';
import ManageUsers from './Components/ManageUsers/ManageUsers';
import GunTV from './Components/GunTV/GunTV';
import BrandLandingPage from './Components/BrandLandingPage/BrandLandingPage';
import HornadyPIM from './Components/HornadyPIM/HornadyPIM';
import HornadyPIMItem from './Components/HornadyPIM/HornadyPIMItem';
import ManufacturerRoute from './Routes/ManufacturerRoute';
import TSWRoutes from './Routes/TSWRoutes';
import Landing from './Components/Landing/Landing';
import Footer from './Components/Footer/Footer';
import SmartOrders from './Components/SmartOrders/SmartOrders';
//import BuildAttr from './Components/HornadyPIM/BuildAttr';

function App() {
  if (window.location.host !== 'localhost:3000') {
    axios.defaults.baseURL = 'https://node.sportssouth.dev';
  }

  const defaultTheme = createTheme(MainTheme);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/gun-tv/*' element={<GunTV />} />
        <Route path='/landing' element={<Landing />} />
        <Route path='/suggested-orders/*' element={<SmartOrders />} />
        <Route
          element={
            <LoginCheckRoute>
              <Navbar />
              <Footer />
            </LoginCheckRoute>
          }
        >
          <Route index element={<Home />} />
          <Route path='/page/*' element={<TSWRoutes />} />
          <Route path='/handshake' element={<Handshake />} />
          <Route path='/account-settings' element={<AccountSettings />} />
          <Route path='/user-preferences' element={<UserPreferences />} />
          <Route path='/manage-users' element={<ManageUsers />} />
          <Route path='/brand-landing-page/:id' element={<BrandLandingPage />} />
          <Route path='/hornady-pim-items' element={<HornadyPIM />} />
          <Route path='/hornady-pim-item/:id' element={<HornadyPIMItem />} />
          <Route path='/manufacturer/*' element={<ManufacturerRoute />} />
          {/* <Route path='/build-attr' element={<BuildAttr />} /> */}
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
