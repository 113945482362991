import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Divider,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import axios from 'axios';

const RowDetailPanel = ({ row }) => {
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const itemNumber = row.original.item_number;

  useEffect(() => {
    axios
      .post(`/suggestedOrders/itemDetail`, {
        itemNumber,
      })
      .then((response) => {
        setProduct(response.data[0]); // Adjusting to handle response as an array
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Failed to fetch product details:', error);
        setIsLoading(false);
      });
  }, [itemNumber]);

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (!product) {
    return <Typography>No product details available</Typography>;
  }

  return (
    <Box sx={{ padding: 3, backgroundColor: '#f5f5f5', borderRadius: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant='h6' gutterBottom>
                {product.brand_name} - {product.model}
              </Typography>
              <Typography variant='subtitle2' color='text.secondary'>
                {product.series} - {product.category_group}
              </Typography>
              <Divider sx={{ marginY: 2 }} />

              {/* Inline list items for more compact layout */}
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <Typography variant='body2' color='text.secondary'>
                    Item Number:
                  </Typography>
                  <Typography variant='body2'>{product.itemno}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography variant='body2' color='text.secondary'>
                    UPC:
                  </Typography>
                  <Typography variant='body2'>{product.upc}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography variant='body2' color='text.secondary'>
                    Manufacturer SKU:
                  </Typography>
                  <Typography variant='body2'>{product.mfgino}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography variant='body2' color='text.secondary'>
                    Department:
                  </Typography>
                  <Typography variant='body2'>{product.department}</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography variant='body2' color='text.secondary'>
                    Category:
                  </Typography>
                  <Typography variant='body2'>{product.category}</Typography>
                </Grid>
              </Grid>

              {/* Facets Display in 2-column Grid */}
              <Typography variant='h6' sx={{ marginTop: 2 }}>
                Specifications:
              </Typography>
              <Grid container spacing={2} sx={{ marginTop: 1 }}>
                {product.facets &&
                  Object.keys(product.facets).map((facetKey) => (
                    <Grid item xs={6} md={4} key={facetKey}>
                      <Typography variant='body2' color='text.secondary'>
                        {facetKey}:
                      </Typography>
                      <Typography variant='body2'>{product.facets[facetKey]}</Typography>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RowDetailPanel;
