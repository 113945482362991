import React from 'react';
import { Box, Button } from '@mui/material';

const TableHeaderActions = ({ table, isDisabled, handleButtonClick }) => {
  return (
    <Box display='flex' justifyContent='space-between'>
      <Button variant='contained' disabled={isDisabled} onClick={handleButtonClick}>
        Confirm Order
      </Button>
    </Box>
  );
};

export default TableHeaderActions;
