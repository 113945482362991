import React from 'react';
import { Box, Typography, TextField } from '@mui/material';

const columns = [
  {
    header: 'Qty',
    accessorKey: 'suggested_qty',
    maxSize: 40,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          variant='outlined'
          size='small'
          value={renderedCellValue}
          sx={{ maxWidth: '100%' }}
        />
      </Box>
    ),
  },
  {
    header: 'Item #',
    accessorKey: 'item_number',
    size: 75,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>
          <a
            href={`https://www.theshootingwarehouse.com/?product_department_id=&product_code=${renderedCellValue}`}
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: '#1976d2', textDecoration: 'underline', cursor: 'pointer' }}
          >
            {renderedCellValue}
          </a>
        </Typography>
      </Box>
    ),
  },
  {
    header: 'UPC #',
    accessorKey: 'upc',
    size: 100,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>{renderedCellValue}</Typography>
      </Box>
    ),
  },
  {
    header: 'SKU #',
    accessorKey: 'manufacturer_sku',
    size: 100,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>{renderedCellValue}</Typography>
      </Box>
    ),
  },
  {
    header: 'Description',
    accessorKey: 'description',
    size: 300,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>{renderedCellValue}</Typography>
      </Box>
    ),
  },
  {
    header: 'Cost',
    accessorKey: 'cost',
    size: 100,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>${renderedCellValue}</Typography> {/* Add $ symbol */}
      </Box>
    ),
  },
  {
    header: 'Last Ordered Days',
    accessorKey: 'last_ordered_days',
    size: 100,
    Cell: ({ renderedCellValue }) => {
      let color;
      if (renderedCellValue <= 3) {
        color = 'red';
      } else if (renderedCellValue >= 4 && renderedCellValue <= 11) {
        color = 'orange';
      } else {
        color = 'inherit'; // Default color for values above 11
      }
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='body1' sx={{ fontWeight: 'bold', color }}>
            {renderedCellValue} {/* No $ symbol here */}
          </Typography>
        </Box>
      );
    },
  },
];

export default columns;
