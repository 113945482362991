import React, { useState, useEffect, useRef } from 'react';
import { Box, Modal, TextField } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import TableRowActions from './Components/TableRowActions';
import TableColumns from './Components/TableColumns';
import LoadingSpinner from '../../../_GlobalComponents/LoadingSpinner';
import TableHeaderActions from './Components/TableHeaderActions';
import OrderConfirmModal from '../OrderConfirmModal/OrderConfirmModal';
import RowDetailPanel from './Components/RowDetailPanel';

const SuggestedOrderTable = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState(''); // State to store the token from the URL

  const effectRan = useRef(false); // Ref to track if useEffect has run

  useEffect(() => {
    if (effectRan.current === false) {
      const path = window.location.pathname;
      const pathParts = path.split('/');
      const tokenFromUrl = pathParts[pathParts.length - 1];
      setToken(tokenFromUrl);

      axios
        .post('/suggestedOrders', {
          customerNumber: tokenFromUrl,
        })
        .then((response) => {
          const dataWithCheckbox = response.data.map((item) => ({
            ...item,
            isChecked: false,
          }));
          setData(dataWithCheckbox);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });

      // Cleanup function to set effectRan to true after the effect runs
      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  const handleCheckChange = (row, isChecked) => {
    const updatedData = data.map((item) =>
      item.item_number === row.original.item_number ? { ...item, isChecked } : item
    );
    setData(updatedData);

    const updatedSelectedRows = updatedData.filter((item) => item.isChecked);
    setSelectedRows(updatedSelectedRows);
  };

  const handleButtonClick = () => {
    if (selectedRows.length > 0) {
      setTimeout(() => {
        setIsModalOpen(true);
      }, 0);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmOrder = (poNumber, shippingMethod) => {
    // Prepare the data to send to the backend
    const items = selectedRows.map((row) => ({
      item_number: row.item_number,
      suggested_qty: row.suggested_qty,
    }));

    axios
      .post('/suggestedOrders/placeOrder', {
        accountNumber: token, // Pass the token to the backend
        poNumber: poNumber,
        shippingMethod: shippingMethod,
        items: items,
      })
      .then((response) => {
        console.log('Order placed successfully');
        setIsModalOpen(false);
        // Handle success, e.g., show a success message
      })
      .catch((error) => {
        console.error('Error placing order:', error);
        // Handle error, e.g., show an error message
      });
  };

  const handleQtyChange = (item_number, newQty) => {
    const updatedData = data.map((item) =>
      item.item_number === item_number ? { ...item, suggested_qty: newQty } : item
    );
    setData(updatedData);
  };

  const tableColumns = TableColumns.map((column) => {
    if (column.accessorKey === 'suggested_qty') {
      return {
        ...column,
        Cell: ({ row, renderedCellValue }) => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              variant='outlined'
              size='small'
              value={renderedCellValue}
              onChange={(e) => handleQtyChange(row.original.item_number, e.target.value)}
              sx={{ width: '100%' }}
            />
          </Box>
        ),
      };
    }
    return column;
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', flexGrow: 1 }}>
      <LoadingSpinner isLoading={isLoading} />
      <MaterialReactTable
        columns={tableColumns}
        data={data}
        enableHiding={false}
        enableToolbarInternalButtons={false}
        initialState={{
          showColumnFilters: false,
          density: 'compact',
          pagination: { pageIndex: 0, pageSize: 20 },
        }}
        positionToolbarAlertBanner='bottom'
        enableStickyHeader
        enableRowActions
        renderRowActions={({ row }) => (
          <TableRowActions row={row} onCheckChange={handleCheckChange} />
        )}
        renderTopToolbarCustomActions={({ table }) => (
          <TableHeaderActions
            table={table}
            isDisabled={selectedRows.length === 0}
            handleButtonClick={handleButtonClick}
          />
        )}
        renderDetailPanel={({ row }) => <RowDetailPanel row={row} />}
        muiTablePaperProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: '100%',
          },
        }}
        muiTableContainerProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'auto',
          },
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: '',
            size: 10,
          },
        }}
      />

      {/* Modal for confirming order */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <OrderConfirmModal
          selectedRows={selectedRows}
          handleClose={handleCloseModal}
          handleConfirm={handleConfirmOrder}
          customerNumber={token}
        />
      </Modal>
    </Box>
  );
};

export default SuggestedOrderTable;
